import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getData } from '../../../services/fetch-service';

function HomeServices(props) {
    const { locale } = props;
    const [page, setPage] = useState([]);
    const [basicpage, setBasicPage] = useState([]);

    useEffect(() => {
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=2&Gjuha=${locale}&skip=0&take=4&TitulliLength=25&PermbajtjaLength=200`).then((response) => {
            setPage(response.data.lajmetLista);
        });
    }, []);
    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=7&Gjuha=${locale}`).then((response) => {
            setBasicPage(response.data[0]);
        });
    }, []);

    const box = page.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri}${item.media?.mediaEx}`;
        return (
            <div key={index} className="content-box">
                <div className="content-header">
                    <Fade big>
                        <img className="content-logo" src={item.media?.otherSource === null ? image : item.media?.otherSource} alt="" />
                        <h3 className="content-title">{item.postimiTitulli}</h3>
                    </Fade>
                </div>
                <Fade big>
                    <p className="contnet-text">{item.postimiPershkrimi}</p>
                    <Link to={item.url} className="btn-pages">
                        <FormattedMessage id="Read.more" defaultMessage="Read More" />
                        <span className="link-line" />
                    </Link>
                </Fade>
            </div>
        )
    })

    return (
        <div className="Services-home-section Page-body">
            <div className="content-grid ">
                <div className="services-description">
                    <Fade big>
                        <h1 className="services-title">{basicpage.pageName}</h1>
                    </Fade>
                    <Fade big>
                        <p className="services-text">{basicpage.pageText}</p>
                    </Fade>
                    <Fade bottom>
                        <Link to="/Services/3" className="btn-pages">
                            <FormattedMessage id="show.more" defaultMessage="Show More" />
                            <span className="link-line" />
                        </Link>
                    </Fade>
                </div>
                <div className="services-boxes">
                    {box}
                </div>

            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(HomeServices);
