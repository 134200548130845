import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import useToggle from '../../header/useToggle';
import { getData } from '../../../services/fetch-service';

function HomeAboutUs(props) {
    const { locale } = props;
    const [vision, toggleVision] = useToggle(true);
    const [mission, toggleMission] = useToggle(false);
    const visionClass = ['btn-pages toggle-title'];
    const missionClass = ['btn-pages toggle-title'];
    const [aboutUs, setAbouts] = useState([]);
    const [missionData, setMission] = useState([]);
    const [visionData, setVisionData] = useState([]);
    // const [subpage, setSubPage] = useState([]);

    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=17&Gjuha=${locale}`).then((response) => {
            setAbouts(response.data[0]);
        });
        getData(`Page/GetPageSubPages?ParentPageID=17&Gjuha=${locale}`).then((response) => {
            setMission(response.data[0].page)
            setVisionData(response.data[1].page);
        });
    }, []);

    if (vision === true) {
        visionClass.push('-active');
    } else if (mission === true) {
        missionClass.push('-active');
    }

    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${aboutUs.media?.mediaEmri}${aboutUs.media?.mediaEx}`;
    return (
        <div className="AboutUs-home-section">
            <div className="content-grid">
                <div className="logo-animation-section">
                    <img className="animation-img" src="/images/about/animation.png" alt="" />
                    <img className="content-img" src={aboutUs.media?.otherSource === null ? image : aboutUs.media?.otherSource} alt="" />
                </div>
                <div className="about-us-description">
                    <div className="about-header">
                        <Fade bottom>
                            <span className="about-sub-title"><FormattedMessage id="Whoarewe?" defaultMessage="Who are we ?" /></span>
                            <h1 className="about-title">{aboutUs.pageName}</h1>
                            <div dangerouslySetInnerHTML={{ __html: aboutUs.pageText }} className="about-text" />
                        </Fade>
                    </div>

                    <div className="toggle-text">
                        <div className="toggle-buttons">
                            <Fade bottom>
                                <button
                                    type="button"
                                    className={visionClass.join('')}
                                    onClick={
                                        () => {
                                            toggleVision(true);
                                            toggleMission(false);
                                        }
                                    }
                                >
                                    {visionData.pageName}
                                    <span className="link-line" />
                                </button>
                            </Fade>
                            <Fade bottom>
                                <button
                                    type="button"
                                    className={missionClass.join('')}
                                    onClick={
                                        () => {
                                            toggleVision(false);
                                            toggleMission(true);
                                        }
                                    }
                                >
                                    {missionData.pageName}
                                    <span className="link-line" />
                                </button>
                            </Fade>
                        </div>
                        <Fade bottom>
                            <div className="text-show">
                                {vision && (
                                    <Fade big>
                                        <div className="toggle-text-show" dangerouslySetInnerHTML={{ __html: visionData.pageText }} />
                                    </Fade>
                                )}
                                {mission && (
                                    <Fade big>
                                        <div className="toggle-text-show" dangerouslySetInnerHTML={{ __html: missionData.pageText }} />
                                    </Fade>
                                )}
                            </div>
                        </Fade>
                        <Fade bottom>
                            <Link to="/AboutUs/2" className="btn-default-sm btn-fam">
                                <Button variant="outlined" size="medium">
                                    <FormattedMessage id="Read.more" defaultMessage="Read More" />
                                </Button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(HomeAboutUs);
