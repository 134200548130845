import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function ServicesBoxes(props) {
    const { locale } = props;
    const [page, setPage] = useState([]);

    useEffect(() => {
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=2&Gjuha=${locale}&skip=0&take=10&TitulliLength=100&PermbajtjaLength=135`).then((response) => {
            setPage(response.data.lajmetLista);
        });
    }, []);

    const box = page.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri}${item.media?.mediaEx}`;
        return (
            <div key={index} className="content-box">
                <div className="content-header">
                    <Fade big>
                        <img className="content-logo" src={item.media?.otherSource === null ? image : item.media?.otherSource} alt="" />
                        <h3 className="content-title">{item.postimiTitulli}</h3>
                    </Fade>
                </div>
                <Fade big>
                    <p className="contnet-text">{item.postimiPershkrimi}</p>
                    <Link to={item.url} className="btn-pages">
                        <FormattedMessage id="Read.more" defaultMessage="Read More" />
                        <span className="link-line" />
                    </Link>
                </Fade>
            </div>
        )
    })

    return (
        <div className="services-boxes">
            {box}
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(ServicesBoxes);
