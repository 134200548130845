// react
import React from 'react';

// third-party
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import Slider from 'react-slick';
// import { FormattedMessage } from 'react-intl';
// import { Link } from 'react-router-dom';
import foto1 from '../../img/logo-slider/logo1.png';
import foto2 from '../../img/logo-slider/logo2.png';
import foto3 from '../../img/logo-slider/logo3.png';
import foto4 from '../../img/logo-slider/logo4.png';

function LogoSlider() {
    return (
        <div className="slider-aboutus">
            <div className="logo-slide">
                <div className="block-posts__slider">
                    <div className="logo-box">
                        <img src={foto1} alt="" />
                    </div>
                    <div className="logo-box">
                        <img src={foto2} alt="" />
                    </div>
                    <div className="logo-box">
                        <img src={foto3} alt="" />
                    </div>
                    <div className="logo-box">
                        <img src={foto4} alt="" />
                    </div>
                    {/* <div className="logo-box">
                        <img src={foto5} alt="" />
                    </div> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(LogoSlider);
