// react
import {
    React,
    useEffect,
    Fragment,
    useState,
} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';
import { getData } from '../../../services/fetch-service';

function Projects(props) {
    const { locale } = props;
    const [project, setProject] = useState([]);

    useEffect(() => {
        getData(`Home/GetMenus?Gjuha=${locale}&LocationMenuID=5`).then((response) => {
            setProject(response.data);
        });
    }, []);

    const DataSlice = project.slice(0, 6);

    const projecthome = DataSlice.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
        return (
            <Fragment key={index}>
                <Fade bottom>
                    <div className="projects-box-link">
                        <img src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource} alt="" />
                        <Link className="info" to={item.url}>
                            <div>
                                <h1>{item.pageName}</h1>
                            </div>
                        </Link>
                    </div>
                </Fade>
            </Fragment>
        )
    })
    return (
        <Fragment>
            <div className="projects-slideshow__project-content Projects-home-section">
                <div className="Page-header-section">
                    <Fade bottom>
                        <h1 className="Page-title">Projects</h1>
                        <p className="Page-sub-title">
                            DataProgNet is the best place to make your future
                            projects live to audiences.
                        </p>
                    </Fade>
                </div>
                <ul className="projects-boxes">
                    {projecthome}
                </ul>
                <Fade bottom>
                    <div className="buttoni-bottom">
                        <Link to="/Projects/4" className="btn-transparent-project btn-fam" type="button"><Button variant="outlined">View all</Button></Link>
                    </div>
                </Fade>
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Projects);
