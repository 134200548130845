// react
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import Fade from 'react-reveal/Fade';
import {
    // Group,
    Pagination,
} from '@mantine/core';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import NewsCard from './NewsCard';
import { getData } from '../../services/fetch-service';
// import Pagination from '../shared/Pagination';
import SkletonNews from '../Skeletons/Skeleton-news';

function News(props) {
    const { locale } = props;
    const [post, setPost] = useState([]);
    const [totalPage, setTotalPage] = useState();
    const [CurrentPage, setCurrentPage] = useState();
    const [NewsCategories, setNewsCategories] = useState([]);
    const [currentCategory, setCurrentCategory] = useState(0);
    const [loader, setLoader] = useState(true);
    const totalpages = totalPage;
    const handleChange = (skip = 0, category = 0) => {
        getData(`News/GetNews?Gjuha=${locale}&PostimiKategoriaID=${category}&skip=${skip}&take=5&TitulliLength=75&PermbajtjaLength=220`).then((response) => {
            setPost(response.data.lajmetLista);
            setTotalPage(response.data.totalPages);
            setLoader(false);
        });
    }
    useEffect(() => {
        getData(`News/GetNewsCategories?Gjuha=${locale}`).then((response) => {
            setNewsCategories(response.data);
        });
        handleChange();
    }, []);

    const handlePageChange = (CurrentPage, categoryupdated) => {
        setCurrentPage(CurrentPage);
        const skip = (CurrentPage * 5) - 5;
        if (categoryupdated === undefined) {
            handleChange(skip, currentCategory);
        } else {
            handleChange(skip, categoryupdated);
        }
    };

    const handleCategoryChange = (e) => {
        setCurrentCategory(e.target.value);
        handlePageChange(1, e.target.value);
    }

    let categorySelect;
    if (locale === 'sq') {
        categorySelect = (
            <MenuItem value="0">Të gjitha</MenuItem>
        )
    } else if (locale === 'en') {
        categorySelect = (
            <MenuItem value="0">All</MenuItem>
        )
    }

    const postsList = post.map((post) => {
        return (
            <NewsCard post={post} />
        );
    });

    return (
        <div className="newsContainer Page-body">
            <div className="news-Header">
                <h1 className="news-header-title">News</h1>
                {/* <select className="form-control form-control-sm form-docs category-select" onChange={handleCategoryChange}>
                    {categorySelect}
                    {NewsCategories.map((kategoria, index) => <option key={index} value={kategoria.postimetKategoriaId}>{kategoria.postimetKategoriaPershkrimi}</option>)}
                </select> */}
                <FormControl className="category-form">
                    {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                    <Select
                        defaultValue="0"
                        variant="outlined"
                        labelId="demo-simple-select-label"
                        className="category-select"
                        id="demo-simple-select"
                        // value="test"
                        label="Categories"
                        onChange={handleCategoryChange}
                    >
                        {categorySelect}
                        {NewsCategories.map((kategoria, index) => <MenuItem key={index} value={kategoria.postimetKategoriaId}>{kategoria.postimetKategoriaPershkrimi}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>

            <Stack className="news-section" spacing={6}>
                {
                    loader === true
                        ? (
                            <SkletonNews />
                        )
                        : (
                            <Fade big>
                                <div className="news-Main">
                                    {postsList}
                                </div>
                            </Fade>
                        )
                }

                <div className="posts-view__pagination">
                    {
                        post.length > 0 ? (
                            <div className="posts-view__pagination">
                                <Pagination
                                    value={CurrentPage}
                                    position="center"
                                    onChange={handlePageChange}
                                    total={totalpages}
                                />
                            </div>
                        ) : ('')
                    }
                </div>
            </Stack>
        </div>

    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(News);
