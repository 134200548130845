// react
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { getData } from '../../services/fetch-service';

function FooterLinks(props) {
    const { locale, layout } = props;
    const [footer, setFooterMenu] = useState([]);

    if (layout === 'footernje') {
        useEffect(() => {
            getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=2`).then((response) => {
                setFooterMenu(response.data);
            })
        }, []);
    } else if (layout === 'footerdy') {
        useEffect(() => {
            getData(`Home/GetMenusWithoutChilds?Gjuha=${locale}&LocationMenuID=3`).then((response) => {
                setFooterMenu(response.data);
            })
        }, []);
    }

    const linksList = footer.map((item, index) => (
        <li key={index} className="footer-links__item list">
            {item.otherSource === true ? (
            <a href={item.url} target={item.targeti} className="footer-links__link ">
                    {item.pageName}
            </a>
                )
                : (
                    <Link to={item.url} target={item.targeti} className="footer-links__link ">
                        {item.pageName}
                    </Link>
                )}
        </li>
    ));

    return (
        <div className="site-footer__widget footer-links">
            {/* <h5 className="footer-links__title">{title}</h5> */}
            <ul className="footer-links__list">
                {linksList.length > 0 ? linksList : ''}
            </ul>
        </div>
    );
}
FooterLinks.propTypes = {
    layout: PropTypes.oneOf(['footernje', 'footerdy']),
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterLinks);
