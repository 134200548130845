import React, {
    useState,
    useEffect,
    useRef,
    useCallback,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import RECAPTCHA from 'react-google-recaptcha';
import { toast } from 'react-toastify';
import { Fade } from 'react-reveal';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';

import { getData, postData } from '../../services/fetch-service';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function Contact(props) {
    const url = window.location.pathname.split('/').pop();
    const params = useParams();
    const { locale } = props;
    const history = useHistory();
    const captchaRef = useRef(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [error, setError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [contactData, setContactData] = useState([]);
    const [pika, setPikat] = useState('');
    const [open, setOpen] = useState(false);
    const [loader, setLoader] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [contact, setContact] = useState({
        name: '',
        email: '',
        subject: 'Email nga forma e kontaktit',
        message: '',
        contactId: 1,
    });

    useEffect(() => {
        getData(`Contact/GetContacts?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setContactData(response.data[0]);
            setPikat(response.data[0]);
            setLoader(false)
        }).catch(() => {
            history.push('/404');
        })
    }, [url]);

    const clearFields = () => {
        setName('');
        setEmail('');
        setMessage('');
        setContact({
            ...contact,
            name: '',
            email: '',
            message: '',
        });
        setDisableSubmit(true);
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    const handleClickOpen = () => {
        setNameError(false);
        setEmailError(false);
        setMessageError(false);
        let validError = false;

        if (name.trim().length === 0) {
            setNameError(true);
            validError = true;
        }

        if (email.trim().length === 0) {
            setEmailError(true);
            validError = true;
        }
        if (!isValidEmail(email)) {
            setEmailError(true);
            validError = true;
        }

        if (message.trim().length === 0) {
            setMessageError(true);
            validError = true;
        }

        if (validError !== true) {
            setOpen(true);
            setError(false);
            setContact({
                ...contact,
                name: name,
                email: email,
                message: message,
            });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setDisableSubmit(true);
    };

    const sendData = () => {
        const token = captchaRef.current.getValue();
        if (error === false) {
            postData(`Contact/SendMail?token=${token}`, contact)
                .then((response) => {
                    if (response.data === false) {
                        toast.error('Gabim gjatë dergimit te mesazhit', { theme: 'colored' });
                        captchaRef.current?.reset();
                    } else {
                        toast.success('Mesazhi u dërgua me sukses', { theme: 'colored' });
                        clearFields();
                        captchaRef.current?.reset();
                    }
                });
            setError(false);
            setOpen(false);
            setDisableSubmit(true);
        } else {
            setError(true);
        }
    }

    const cordinates = `https://maps.google.com/maps?q=${pika.gps}&hl=es;z=14&amp;output=embed`;

    return (
        <div className="Contact">
            <div className="contact-bg">
                <div className="bg-blur-1" />
                <div className="bg-blur-2" />
                <div className="bg-blur-3" />
            </div>

            <div className="contact-section">
                <div className="contact-box">
                    <div className="bg-left" />
                    <div className="bg-right" />
                    <div className="contact-display-contnet">
                        <div className="contact-form">
                            <Box sx={{ width: '100%' }}>
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="flex-start"
                                >
                                    <div className="contact-description">
                                        <div className="contact-header">
                                            <h1><FormattedMessage id="Get in" defaultMessage="Get in" /></h1>
                                            <span><FormattedMessage id="Touch" defaultMessage="Touch" /></span>
                                        </div>
                                        <p className="contact-text"><FormattedMessage id="Contact.short" defaultMessage="Do you want to get in touch? We'd be delighted to hear from you. To reach us, please use the form below." /></p>
                                    </div>
                                    <TextField
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                        error={nameError}
                                        fullWidth
                                        required
                                        className="contact-input"
                                        id="outlined-basic"
                                        label="Name"
                                        variant="outlined"
                                    />
                                    <TextField
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        error={emailError}
                                        fullWidth
                                        type="email"
                                        className="contact-input"
                                        required
                                        id="outlined-basic"
                                        label="Email"
                                        variant="outlined"
                                    />
                                    <TextField
                                        onChange={(e) => setMessage(e.target.value)}
                                        value={message}
                                        required
                                        fullWidth
                                        className="contact-input"
                                        id="outlined-multiline-static"
                                        label="Message"
                                        multiline
                                        rows={4}
                                        error={messageError}
                                        placeholder="Enter message here"
                                    />
                                    <Button
                                        className="btn-contact"
                                        variant="contained"
                                        onClick={handleClickOpen}
                                    >
                                        <FormattedMessage id="Send" defaultMessage="Send" />
                                    </Button>
                                    <BootstrapDialog
                                        onClose={handleClose}
                                        aria-labelledby="customized-dialog-title"
                                        open={open}
                                    >
                                        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                                            Captcha
                                        </BootstrapDialogTitle>
                                        <DialogContent className="captcha-section" dividers>
                                            <div className="captcha-box">
                                                    <RECAPTCHA
                                                        className="form-group captcha"
                                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                                        ref={captchaRef}
                                                        onChange={useCallback(() => setDisableSubmit(false))}
                                                    />
                                            </div>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button autoFocus disabled={disableSubmit} onClick={sendData}>
                                                <FormattedMessage id="Confirm" defaultMessage="Confrim" />
                                            </Button>
                                        </DialogActions>
                                    </BootstrapDialog>
                                    <div className="contact-bottom-section">
                                        {loader === true ? ('') : (
                                            <Fade className="contact-fade">
                                                <Grid className="contact-bottom-info" container spacing={2} columns={18}>
                                                    <Grid className="grid-bottom" xs={6} lg={6}>
                                                        <img src="/images/ico/phone.png" alt="" />
                                                        <div className="grid-info">
                                                            <h6 className="grid-title">
                                                                <FormattedMessage id="Phone" defaultMessage="PHONE" />
                                                            </h6>
                                                            <a href={`tel:${contactData.telefoni}`}>{contactData.telefoni}</a>
                                                        </div>
                                                    </Grid>
                                                    <Grid className="grid-bottom" xs={6} lg={6}>
                                                        <img src="/images/ico/email.png" alt="" />
                                                        <div className="grid-info grid-email">
                                                            <h6 className="grid-title">
                                                                <FormattedMessage id="Email" defaultMessage="EMAIL" />
                                                            </h6>
                                                            <a href={`mailto:${contactData.emaili}`}>{contactData.emaili}</a>
                                                        </div>
                                                    </Grid>
                                                    <Grid className="grid-bottom" xs={6} lg={6}>
                                                        <img src="/images/ico/location.png" alt="" />
                                                        <div className="grid-info grid-address">
                                                            <h6 className="grid-title">
                                                                <FormattedMessage id="Location" defaultMessage="LOCATION" />
                                                            </h6>
                                                            <a rel="noreferrer" target="_blank" href={`https://www.google.com/maps/place/${contactData.gps}`}>{contactData.adresa}</a>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Fade>
                                        )}
                                    </div>
                                </Stack>
                            </Box>
                        </div>
                        <div
                            className="contact-map"
                            dangerouslySetInnerHTML={{
                                __html:
                                    `<iframe
                                    className="content-img"            
                                    title="Google Map"                                
                                    src="${cordinates}"
                                    ="0"
                                    scrolling="no"
                                    marginHeight="0"
                                    marginWidth="0"
                                />`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Contact);
