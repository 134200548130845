import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { FormattedMessage } from 'react-intl';
import ServicesBoxes from './ServicesBoxes';
import { getData } from '../../services/fetch-service';

function Services(props) {
    const { locale } = props;
    const [ServiceNews, setServiceNews] = useState([]);
    const [ServiceNews2, setServiceNews2] = useState([]);

    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=20&Gjuha=${locale}`).then((response) => {
            setServiceNews(response.data[0]);
        });
        getData(`Page/GetBasicPage?PageID=21&Gjuha=${locale}`).then((response) => {
            setServiceNews2(response.data[0]);
        });
    }, []);

    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${ServiceNews.media?.mediaEmri}${ServiceNews.media?.mediaEx}`;
    const image2 = `${path}${ServiceNews2.media?.mediaEmri}${ServiceNews2.media?.mediaEx}`;
    return (
        <div className="Services-section">
            <div className="Services-body">
                <div className="services-top Page-body">
                    <div className="Page-header-section">
                        <h1 className="Page-title">SERVICES</h1>
                        <p className="Page-sub-title">
                            DataProgNet is the best place to make your future
                            projects live to audiences.
                        </p>
                    </div>
                    <div className="Services-content">
                        <div className="card-left">
                            <Fade left>
                                <div className="card-image">
                                    {
                                        ServiceNews.media !== null && (
                                            <img loading="lazy" src={ServiceNews.media?.otherSource === null ? image : ServiceNews.media?.otherSource} alt="" />
                                        )
                                    }
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="card-textContent">
                                    <h3 className="card-title">{ServiceNews.pageName}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{ __html: ServiceNews.pageText }}
                                        className="card-text"
                                    />
                                    <Link to={`Page/${ServiceNews.pageId}`} className="btn-pages">
                                        <FormattedMessage id="services.btn.left" defaultMessage="Request a Service" />
                                        <span className="link-line" />
                                    </Link>
                                </div>
                            </Fade>

                        </div>
                        <div className="card-right">
                            <Fade big>
                                <div className="card-textContent">
                                    <h3 className="card-title">{ServiceNews2.pageName}</h3>
                                    <p
                                        dangerouslySetInnerHTML={{ __html: ServiceNews2.pageText }}
                                        className="card-text"
                                    />
                                    <Link to={`Page/${ServiceNews2.pageId}`} className="btn-pages">
                                        <FormattedMessage id="services.btn.left" defaultMessage="Request a Service" />
                                        <span className="link-line" />
                                    </Link>

                                </div>
                            </Fade>
                            <Fade bottom>
                                <div className="card-image">
                                    {
                                        ServiceNews2.media !== null && (
                                            <img loading="lazy" src={ServiceNews2.media?.otherSource === null ? image2 : ServiceNews2.media?.otherSource} alt="" />
                                        )
                                    }
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <ServicesBoxes />
                </div>
            </div>
        </div>
    );
}

export default Services;
