import axios from 'axios';

const API_URL = process.env.REACT_APP_BASEURL;

const getData = (action) => {
    return axios.get(API_URL + action);
};
const postData = (action, data) => {
    return axios.post(API_URL + action, data);
};
const putData = (action, data) => {
    return axios.put(API_URL + action, data);
};
const deleteData = (action) => {
    return axios.delete(API_URL + action);
};

export {
    getData,
    postData,
    putData,
    deleteData,
};
