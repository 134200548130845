import React, { Fragment } from 'react';

// third-party
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import NavLinks from './NavLinks2';
// import SearchScroll from './SearchScroll';

function NavPanel(props) {
    const { locale } = props;
    const navbarClasses = ['nav-bar'];

    let logo;

    if (locale === 'sq') {
        logo = (
            <div className="nav-bar-logo">
                <Link to="/Home"><img src="/images/logot/logo.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'en') {
        logo = (
            <div className="nav-bar-logo">
                <Link to="/Home"><img src="/images/logot/logo.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'sr') {
        logo = (
            <div className="nav-bar-logo">
                <Link to="/Home"><img src="/images/logot/logo.png" alt="" /></Link>
            </div>
        );
    }

    return (
        <Fragment>
            <div className={navbarClasses.join('')}>
                <div className="nav-bar__container">
                    <div className="nav-bar-logo">
                        {logo}
                    </div>

                    <div className="nav-bar__row">
                        <div className="nav-bar__nav-links nav-links">
                            <NavLinks layout="default" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>

    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
