// react
import { React, useState, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Image, Breadcrumbs, Anchor } from '@mantine/core';

import { getData } from '../../services/fetch-service';
import SubPage from './SubPage';

function Page(props) {
    const history = useHistory();
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [Page, setPage] = useState([]);
    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=${params.id}&Gjuha=${locale}`).then((response) => {
            setPage(response.data);
        }).catch(() => {
            history.push('/404');
        });
    }, [url]);
    const path = process.env.REACT_APP_FILESPATH;

    const items = [
        { title: 'Home', href: '/Home' },
        { title: 'Page', href: '#' },
    ].map((item, index) => (
        <Anchor key={index}>
            <Link to={item.href}>
                {item.title}
            </Link>
        </Anchor>
    ));

    return (
        <div className="page-section basic-page">
            <div className="page-breadcrumbs">
                <Breadcrumbs>{items}</Breadcrumbs>
            </div>
            {
                Page.map((page, key) => {
                    const image = page.media?.otherSource === null ? `${path}${page.media?.mediaEmri}${page.media?.mediaEx}` : page.media?.otherSource;
                    return (
                        <div className="page-content" key={key}>
                            <h1 className="page-title">
                                {page.pageName}
                            </h1>
                            <div className="page-box">
                                <Fade>
                                    <div className="card-image">
                                        <Image src={image} />
                                    </div>
                                </Fade>
                                <div
                                    dangerouslySetInnerHTML={{ __html: page.pageText }}
                                    className="page-text"
                                />
                            </div>
                        </div>
                    );
                })
            }
            {
                Page.map((page, key) => {
                    return (
                        <div key={key}>
                            {page.hasSubPages === true && (
                                <SubPage />
                            )}
                        </div>
                    );
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Page);
