import React, { useState, useEffect } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';
import { getData } from '../../services/fetch-service';

function MobileMenu(props) {
    const { locale } = props;
    const {
        closeMobileMenu,
        mobileMenuState,
    } = props;

    const [mobileMenuLinks, setAllLinks] = useState([]);

    // const handleClick = () => {
    //     closeMobileMenu();
    //     toggleValue((prev) => !prev);
    //     closeMobileMenu();
    // };

    useEffect(() => {
        getData(`Home/GetMenus?LocationMenuID=1&Gjuha=${locale}`).then((response) => {
            setAllLinks(response.data);
        });
    }, []);

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const handleItemClick = (item) => {
        if (item.hasChild === true) {
            closeMobileMenu();
        } else {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>

            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                {/* <div className="mobilemenu__header">
                    <Link to={{ pathname: '/home' }} onClick={handleItemClick}>
                        <div className="mobile-links__item-link home-link">
                            <FormattedMessage className="mobile-links__item" id="MobileMenu.Home" defaultMessage="Ballina" />
                        </div>
                    </Link>
                </div> */}
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks} onItemClick={handleItemClick} />
                </div>

                {/* <div className="mobile-howdoi">
                    <button
                        className="how-do-i-btn"
                        type="button"
                        onClick={() => toggleValue((prev) => !prev)}
                    >
                        <FormattedMessage id="how.do.i" defaultMessage="Example Text" />
                        <span className="search-plus">+</span>
                    </button>
                </div> */}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    locale: state.locale,
});

MobileMenu.propTypes = {
    locale: PropTypes.string,
};

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
