import {
    React,
    useEffect,
    // Fragment,
    useState,
} from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';

import useToggle from '../header/useToggle';
import { getData } from '../../services/fetch-service';

function ProjectsPage(props) {
    // const [vision, toggleVision] = useToggle(true);
    const vision = useToggle(true);
    const visionClass = ['btn-pages toggle-title'];

    const { locale } = props;
    const [projects, setProjects] = useState([]);
    const [visible, setVisible] = useState(9)

    useEffect(() => {
        getData(`Home/GetMenus?Gjuha=${locale}&LocationMenuID=6`).then((response) => {
            setProjects(response.data);
        });
    }, []);

    if (vision === true) {
        visionClass.push('-active');
    }

    const pages = projects.length

    const DataSlice = projects.slice(0, visible);

    // if (visible === pages || visible > pages) {
    //     console.log('last page');
    // } else {
    //     console.log('page');
    // }

    const showMore = () => {
        setVisible((prevValue) => prevValue + 6);
    }

    const projectpage = DataSlice.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media[0]?.mediaEmri}${item.media[0]?.mediaEx}`;
        return (
            <div className="projects-box-link" key={index}>
                <Fade>
                    <img loading="lazy" src={item.media[0]?.otherSource === null ? image : item.media[0]?.otherSource} alt="" />
                </Fade>
                <Link className="info" to={item.url}>
                    <div>
                        <h1>{item.pageName}</h1>
                    </div>
                </Link>
            </div>
        )
    })
    return (
        <div className="Projects-section Page-body">
            <div className="Page-header-section">
                <h1 className="Page-title">PROJECTS</h1>
                <p className="Page-sub-title">
                    DataProgNet is the best place to make your future
                    projects live to audiences.
                </p>
            </div>

            <div className="toggle-section">
                {/* <div className="toggle-buttons">
                    <Fade bottom>
                        <button
                            type="button"
                            className={visionClass.join('')}
                            onClick={
                                () => {
                                    toggleVision(true);
                                }
                            }
                        >
                            <FormattedMessage id="All.Projects" defaultMessage="All Projects" />
                        </button>
                    </Fade>
                </div> */}
                <Fade bottom>
                    <div className="toggle-show">
                        {vision && (
                            <div className="toggle-content project-boxes-content">
                                <Fade>
                                    <div className="projects-boxes">
                                        {projectpage}
                                    </div>
                                </Fade>
                                {visible < pages ? (
                                    <div className="buttoni-bottom">
                                        <div className="btn-transparent-project btn-fam" type="button">
                                            <Button onClick={showMore} variant="outlined">Show more</Button>
                                        </div>
                                    </div>
                                ) : ('')}
                            </div>
                        )}
                    </div>
                </Fade>

            </div>

            <div className="animation-img-bg">
                <img className="animation-img" src="/images/about/animation.png" alt="" />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(ProjectsPage);
