import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import ToTop from './ToTop';
import SocialLinks from '../shared/SocialLinks';

function Footer(props) {
    const getCurrentYear = () => {
        return new Date().getFullYear();
    };
    const { locale } = props;

    let logo;

    if (locale === 'sq') {
        logo = (
            <div className="site-footer__logo">
                <Link to="/Home"><img src="/images/logot/logo.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'en') {
        logo = (
            <div className="site-footer__logo">
                <Link to="/Home"><img src="/images/logot/logo.png" alt="" /></Link>
            </div>
        );
    } else if (locale === 'sr') {
        logo = (
            <div className="site-footer__logo">
                <Link to="/Home"><img src="/images/logot/logo.png" alt="" /></Link>
            </div>
        );
    }

    return (
        <div className="site-footer">
            <div className="footer">
                <div className="site-footer__widgets">
                    <div className="row footer-row">
                        <div className="links-section-footer">
                            <div className="col-lg-4 col-xl-3 footer-img-box">
                                <div className="logo-footer-grid">
                                    {logo}
                                    <SocialLinks className="footer-newsletter__social-links" shape="circle" />
                                </div>
                            </div>
                            <div className="footer-nav">
                                <div className="links-ft">
                                    <div className="footer-links-box">
                                        <FooterLinks layout="footerdy" />
                                    </div>
                                </div>
                                <div className="links-ft">
                                    <div className="footer-links-box">
                                        <FooterLinks layout="footernje" />
                                    </div>
                                </div>
                                {/* <div className="links-ft">
                                <div className="footer-links-box">
                                    <FooterVegezat />
                                </div>
                            </div> */}
                                <div className="links-ft">
                                    <div className="footer-links-box">
                                        <FooterContacts />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-footer__bottom">
                <div className="copyright-container">
                    <div className="footer__bottom">
                        <div className="site-footer__copyright">
                            ©
                            {' '}
                            2004 -
                            {' '}
                            {getCurrentYear()}
                            {' '}
                            <FormattedMessage id="Copyrights" defaultMessage="Copyrights" />
                            {' '}
                        </div>
                    </div>
                </div>
            </div>
            <div className="sticky-button">
                <ToTop />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Footer);
