import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function SkletonNews() {
    return (
        <div className="news-Main">
            <div className="news">
                <div className="news-img-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-textContent">
                    <div className="news-header-skeleton">
                        <Skeleton animation="wave" />
                    </div>
                    <p className="news-text">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </p>
                    <div className="readMoreButton-skeleton">
                        <Skeleton animation="wave" />
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="news-img-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-textContent">
                    <div className="news-header-skeleton">
                        <Skeleton animation="wave" />
                    </div>
                    <p className="news-text">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </p>
                    <div className="readMoreButton-skeleton">
                        <Skeleton animation="wave" />
                    </div>
                </div>
            </div>
            <div className="news">
                <div className="news-img-skeleton">
                    <Skeleton variant="rectangular" />
                </div>
                <div className="news-textContent">
                    <div className="news-header-skeleton">
                        <Skeleton animation="wave" />
                    </div>
                    <p className="news-text">
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                        <Skeleton animation="wave" />
                    </p>
                    <div className="readMoreButton-skeleton">
                        <Skeleton animation="wave" />
                    </div>
                </div>
            </div>

        </div>
    );
}
