// application
import enMessages from './messages/en.json';
import shqMessages from './messages/shq.json';

export default {
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    sq: {
        messages: shqMessages,
        direction: 'ltr',
    },
};
