import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { getData } from '../../services/fetch-service';

function AboutBoxes(props) {
    const { locale } = props;
    const [aboutUsBox, setAboutUsBox] = useState([]);

    useEffect(() => {
        getData(`News/GetNews?PageId=0&PostimiKategoriaID=5&Gjuha=${locale}&skip=0&take=3&TitulliLength=100&PermbajtjaLength=120`).then((response) => {
            setAboutUsBox(response.data.lajmetLista);
        });
    }, []);

    const box = aboutUsBox.map((item, index) => {
        const path = process.env.REACT_APP_FILESPATH;
        const image = `${path}${item.media?.mediaEmri}${item.media?.mediaEx}`;
        return (
            <div key={index} className="aboutbox">
                <img src={item.media?.otherSource === null ? image : item.media?.otherSource} alt="network icon" />
                <h3 className="aboutusbox-title">
                    {item.postimiTitulli}
                </h3>
                <p className="aboutusbox-text">
                    {item.postimiPershkrimi}
                </p>
            </div>
        );
    });
    return (
        <div className="aboutusContainer">
            <div className="Page-header-section">
                <Fade bottom>
                    <h1 className="Page-title">Overcome Technical Barriers</h1>
                    <p className="Page-sub-title">
                        There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectec
                        humour, or randomised words which dont look even slightly believable.
                    </p>
                </Fade>
            </div>
            <Fade bottom cascade>
                <div className="aboutBoxes-main">
                    {box}
                </div>
            </Fade>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(AboutBoxes);
