import React, { useMemo } from 'react';
import HomeSlider from '../sliders/HomeSlider';
import HomeAboutUs from './Sections/AboutUs-home';
import Projects from './Sections/Projects';
import HomeServices from './Sections/Services-home';
// import NewsBlog from '../NewsBlog/NewsBlog';
// import useToggle from '../../header/useToggle';

function HomePageOne() {
    return (
        <React.Fragment>
            {useMemo(() => <HomeSlider />, [])}
            {useMemo(() => <HomeServices />, [])}
            {useMemo(() => <HomeAboutUs />, [])}
            {useMemo(() => <Projects />, [])}
        </React.Fragment>
    );
}

export default HomePageOne;
