// react
import {
    React,
    useState,
    useEffect,
    Fragment,
} from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Image, Breadcrumbs, Anchor } from '@mantine/core';
import Fade from 'react-reveal/Fade';
import { getData } from '../../services/fetch-service';
import ProductGallery from '../shared/ProductGallery';

function NewsDetails(props) {
    const history = useHistory();
    const params = useParams();
    const { locale } = props;
    const url = window.location.pathname.split('/').pop();
    const [newsDetails, setNewsDetails] = useState([]);
    const [MediaPostimet, setMediaPostimet] = useState([]);

    console.log(MediaPostimet)

    useEffect(() => {
        getData(`News/GetNewsDetail?PostimiID=${params.postimiId}&Gjuha=${locale}`).then((response) => {
            setNewsDetails(response.data.postimi);
            setMediaPostimet(response.data.mediaPostimi);
        }).catch(() => {
            history.push('/404');
        });
    }, [url]);

    const path = process.env.REACT_APP_FILESPATH;
    const items = [
        { title: 'Home', href: '/Home' },
        { title: 'News Details', href: '#' },
    ].map((item, index) => (
        <Anchor key={index}>
            <Link to={item.href}>
                {item.title}
            </Link>
        </Anchor>
    ));

    const image = newsDetails.media?.otherSource === null ? `${path}${newsDetails.media?.mediaEmri}${newsDetails.media?.mediaEx}` : newsDetails.media?.otherSource;

    return (
        <div className="page-section">
            <div className="page-breadcrumbs-half">
                <Breadcrumbs>{items}</Breadcrumbs>
            </div>

            <div className="page-content-half">
                <h1 className="page-title-half">
                    {newsDetails.postimiTitulli}
                </h1>
                {
                    newsDetails.postimiKategoria !== 'Service - Home' && (
                        <Fragment>
                            {
                                newsDetails.media !== null && (
                                    <Fade>
                                        <div className="card-image-half">
                                            <Image src={image} alt="" />
                                        </div>
                                    </Fade>
                                )
                            }
                        </Fragment>

                    )
                }
                <Fade cascade>
                    <div
                        dangerouslySetInnerHTML={{ __html: newsDetails.postimiPershkrimi }}
                        className="news-text"
                    />
                    <div
                        dangerouslySetInnerHTML={{ __html: newsDetails.postimiPermbajtja }}
                        className="page-text"
                    />
                </Fade>
                {MediaPostimet.length === 0 ? ('') : (
                    <div className="news-galeryDetails">
                        <ProductGallery images={MediaPostimet} />
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsDetails);
