// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// application
import Footer from './footer';
import Header from './header';
import MobileHeader from './mobile/MobileHeader';
import MobilePanel from './mobile/mobilePanel';
// import BlockContats from './blocks/BlockContats'
// data stubs
import theme from '../data/theme';
import FooterSlider from './sliders/FooterSlider';

function Layout(props) {
    const {
        match,
        headerLayout,
        homeComponent,
        ContactBottom,
    } = props;

    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta name="description" content={theme.fullName} />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar />

            <MobilePanel layout={headerLayout} />

            <div className="site">
                <header className="site__header mobile-nav">
                    <MobileHeader />
                </header>

                <header className="site__header dekstop-nav">
                    <Header layout={headerLayout} />
                </header>

                <div className="site__body">
                    <Switch>
                        <Route exact path={`${match.path}`} component={homeComponent} />
                    </Switch>
                </div>

                <footer className="site__footer">
                    <FooterSlider ContactBottom={ContactBottom} />
                    <Footer />
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    headerLayout: PropTypes.oneOf(['default', 'test']),
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: 'default',
};

export default Layout;
