// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import StroykaSlick from '../shared/StroykaSlick';

// application
import departmentsAria from '../../services/departmentsArea';
// import languages from '../../i18n';

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    componentDidMount() {
        // const params = useParams();

        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        return (
            <div className="Slider-Section">
                <div className="Slider-Slick">
                    <StroykaSlick
                        ref={this.setSlickRef}
                        {...slickSettings}
                    >
                        <div className="Slider-home">
                            <div
                                className="Slider-img"

                            >
                                <div
                                    className="slider-bg"
                                    style={{ backgroundImage: 'url(\'/desk/inc/Media/8834155A-170A-4414-9E7B-B8DA1BB33364.jpg\')' }}
                                />
                                {/* <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/default-home.png"
                            src={slide.media.otherSource === null ? image : slide.media.otherSource}
                        /> */}
                                <div className="overlay" />
                            </div>
                            <div className="Slider-Caption">
                                <Fade bottom>
                                    <h1 className="caption-title">KODE PROJECT</h1>
                                    <div className="course-text-title">
                                        <p className="caption-text"> Free trainings in Mern full stack & Digital Marketing</p>
                                        <span>Ferizaj</span>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </StroykaSlick>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
