export default {
    name: 'DataProgNet',
    fullName: '',
    url: '',
    author: {
        name: '',
        profile_url: '',
    },
    contacts: {
        address: 'Ndërtesa e Qeverisë, Kati 11, Rruga Nënë Tereza, Prishtinë',
        email: 'antoneta.azemi@rks-gov.net',
        phone: '038/ 200 34 104',
    },
};
