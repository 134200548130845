// react
import {
    React,
    Fragment,
    useState,
    useEffect,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import { getData } from '../../services/fetch-service';

function FooterSlider(props) {
    const { ContactBottom, locale } = props;
    const params = useParams();
    const url = window.location.pathname.split('/').pop();
    const [foterSlider, setFoterSlider] = useState([]);
    const [sliderTitle, setSliderTitle] = useState('');

    useEffect(() => {
        getData(`Home/GetSlides?Gjuha=${locale}&PageID=${params.id === undefined ? 0 : params.id}`).then((response) => {
            setFoterSlider(response.data[0]?.media);
            setSliderTitle(response.data[0].sllideTitulli);
        });
    }, [url]);

    const path = process.env.REACT_APP_FILESPATH;
    const image = foterSlider?.otherSource === null ? `${path}${foterSlider?.mediaEmri}${foterSlider?.mediaEx}` : foterSlider?.otherSource;
    return (
        <Fragment>
            {ContactBottom !== 'false' ? (
                <div className="footer-top">
                    <div
                        className="photo-menu-home"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    >
                        <div className="block-slideshow__slide-content">
                            <h1 className="footer-parallax-title">{sliderTitle}</h1>
                            <Link className="btn-transparent btn-fam" to="/Contact/6">
                                <Button size="large">
                                    <FormattedMessage id="CONTACT US" defaultMessage="CONTACT US" />
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="overlay" />
                </div>
            ) : ('')}
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(FooterSlider);
