import React, { Fragment, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
// import LogoSlider from './LogoSlider';
import MissionVision from './MissionVision';
import AboutBoxes from './AboutBoxes';
// import { FormattedMessage } from 'react-intl';
import { getData } from '../../services/fetch-service';

function AboutUs(props) {
    const { locale } = props;
    const [aboutUs, setAboutUs] = useState([]);
    const [loader, setLoader] = useState(true);
    useEffect(() => {
        getData(`Page/GetBasicPage?PageID=2&Gjuha=${locale}`).then((response) => {
            setAboutUs(response.data[0]);
            setLoader(false);
        });
    }, []);
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${aboutUs.media?.mediaEmri}${aboutUs.media?.mediaEx}`;
    return (
        <Fragment>
            <div className="AboutUs-section Page-body">
                <div className="Page-header-section">
                    <h1 className="Page-title">WHO WE ARE?</h1>
                    <p className="Page-sub-title">
                        DataProgNet is a software development company
                        established in 2004
                    </p>
                </div>
                {
                    loader === true
                        ? (
                            <div className="content-grid-skeleton" />
                        )
                        : (
                            // <Fade big>
                            <div className="content-grid">
                                <div className="about-us-description">
                                    <div className="about-header">
                                        <Fade bottom>
                                            <h1 className="about-title">{aboutUs.pageName}</h1>
                                            <p dangerouslySetInnerHTML={{ __html: aboutUs.pageText }} className="about-text" />
                                        </Fade>
                                    </div>
                                </div>
                                <Fade>
                                    <div className="logo-animation-section">
                                        {/* <img className="animation-img" src="/images/about/animation.png" alt="" /> */}
                                        <img className="content-img" src={aboutUs.media?.otherSource === null ? image : aboutUs.media?.otherSource} alt="" />
                                    </div>
                                </Fade>
                            </div>
                            // </Fade>
                        )
                }
            </div>
            {/* <LogoSlider /> */}
            <MissionVision />
            <AboutBoxes />
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(AboutUs);
