import React from 'react';
import Moment from 'moment';
// third-party
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

function NewsCard(props) {
    const { post, locale } = props;
    const path = process.env.REACT_APP_FILESPATH;
    const image = `${path}${post.media?.mediaEmri}${post.media?.mediaEx}`;
    const cardClasses = classNames('news');
    return (
        <div className={cardClasses}>
            <div className="news-img-box">
                <Link to={post.url} className="news-img">
                    {
                        post.media === null ? (
                            <img className="news-img-default" src="/images/blur/solid.jpg" alt="" />
                        ) : (
                            <LazyLoadImage
                                alt=""
                                effect="blur"
                                placeholderSrc="/images/blur/solid.jpg"
                                src={post.media?.otherSource === null ? image : post.media?.otherSource}
                            />
                        )
                    }
                </Link>
            </div>
            <div className="news-textContent">
                <div className="news-header">
                    <Link to={post.url}><h3 className="news-title">{post.postimiTitulli}</h3></Link>
                    <p className="news-date">
                        {Moment(post.postimiDataFillimit).locale(locale).format('LL')}
                    </p>
                </div>
                <p className="news-text">
                    {post.postimiPershkrimi}
                </p>
                <div className="readMoreButton">
                    <Link className="btn-pages" to={post.url}>
                        <FormattedMessage id="Read.More" defaultMessage="Read More" />
                        <span className="link-line" />
                    </Link>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(NewsCard);
