// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import Button from '@mui/material/Button';
import Fade from 'react-reveal/Fade';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import StroykaSlick from '../shared/StroykaSlick';
import { getData } from '../../services/fetch-service';

// application
import departmentsAria from '../../services/departmentsArea';
// import languages from '../../i18n';

const slickSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 4000,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    constructor(props) {
        super(props);
        this.state = {
            slides: [],
        };
    }

    componentDidMount() {
        const { locale } = this.props;
        getData(`Home/GetSlides?Gjuha=${locale}&PageID=1`).then((response) => {
            this.setState({
                slides: response.data,
            });
        });
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            // noinspection JSDeprecatedSymbols
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    handleNextClick = () => {
        if (this.slickRef) {
            this.slickRef.slickNext();
        }
    };

    handlePrevClick = () => {
        if (this.slickRef) {
            this.slickRef.slickPrev();
        }
    };

    setSlickRef = (ref) => {
        this.slickRef = ref;
    };

    render() {
        const { slides } = this.state;
        const path = process.env.REACT_APP_FILESPATH;
        const slidess = slides.map((slide, index) => {
            const image = slide.media?.otherSource === null ? `${path}${slide.media?.mediaEmri}${slide.media?.mediaEx}` : slide.media?.otherSource;

            return (
                <div key={index} className="Slider-home">
                    <div
                        className="Slider-img"

                    >
                        <div
                            className="slider-bg"
                            style={{
                                backgroundImage: "url('/images/blur/default-home.png')",
                            }}
                        />
                        <Fade big>
                            <img loading="lazy" src={slide.media.otherSource === null ? image : slide.media.otherSource} alt="" />
                        </Fade>
                        {/* <LazyLoadImage
                            alt=""
                            effect="blur"
                            placeholderSrc="/images/blur/default-home.png"
                            src={slide.media.otherSource === null ? image : slide.media.otherSource}
                        /> */}
                        <div className="overlay" />
                    </div>
                    <div className="Slider-Caption">
                        <Fade bottom>
                            <h1 className="caption-title" to={slide.linku}>{slide.sllideTitulli}</h1>
                            <p className="caption-text">{slide.sllidePershkrimi}</p>
                            {slide.linku !== '' ? (
                                <Link to={slide.linku} className="btn-default btn-fam">
                                    <Button size="large">
                                        <FormattedMessage id="Read.more" defaultMessage="Read More" />
                                    </Button>
                                </Link>
                            ) : ('')}

                        </Fade>
                    </div>
                </div>
            );
        });

        return (
            <div className="Slider-Section">
                <div className="Slider-Slick">
                    <StroykaSlick
                        ref={this.setSlickRef}
                        {...slickSettings}
                    >
                        {slidess}
                    </StroykaSlick>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
