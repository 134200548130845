import React from 'react';
import NavPanel from './NavPanel';

function Header() {
    return (
        <div className="site-header">
            <div className="site-header__nav-bar">
                <NavPanel layout="default" />
            </div>
        </div>
    );
}

export default Header;
