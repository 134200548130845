import React, { Fragment } from 'react';
import Fade from 'react-reveal/Fade';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import LogoSlider from '../about-us/LogoSlider';
import useToggle from '../header/useToggle';
import PageSlider from '../sliders/PageSlider';

function Trainings() {
    const [vision, toggleVision] = useToggle(true);
    const [mission, toggleMission] = useToggle(false);
    const visionClass = ['btn-pages toggle-title'];
    const missionClass = ['btn-pages toggle-title'];

    if (vision === true) {
        visionClass.push('-active');
    } else if (mission === true) {
        missionClass.push('-active');
    }

    return (
        <Fragment>
            <div className="Trainings-section Page-body">
                <PageSlider />
                <div className="toggle-text">
                    <div className="toggle-buttons">
                        <Fade bottom>
                            <button
                                type="button"
                                className={visionClass.join('')}
                                onClick={
                                    () => {
                                        toggleVision(true);
                                        toggleMission(false);
                                    }
                                }
                            >
                                MERN Full Stack
                                <span className="link-line" />
                            </button>
                        </Fade>
                        <Fade bottom>
                            <button
                                type="button"
                                className={missionClass.join('')}
                                onClick={
                                    () => {
                                        toggleVision(false);
                                        toggleMission(true);
                                    }
                                }
                            >
                                Digital Marketing
                                <span className="link-line" />
                            </button>
                        </Fade>
                    </div>
                    <Fade bottom>
                        <div className="text-show">
                            {vision && (
                                <div className="content-grid">
                                    <Fade cascade>
                                        <div className="course-description">
                                            <div className="course-header">
                                                <h1 className="course-title">MERN Full Stack Developer with Network Basic Skills</h1>
                                                <p className="course-text">
                                                    This course will introduce and enable students to apply fundamentals
                                                    computer networks, create, design, manipulate and managing MongoDB databases and programing
                                                    in Express JS, Node JS and React JS for creating powerful modern software application.
                                                    Targets are individuals who are new to prepared and successfully apply the concepts and
                                                    techniques of computers networks, database and programming techniques and individuals
                                                    who have some experience.
                                                </p>
                                            </div>
                                            <div className="course-text">
                                                <div className="course-content">
                                                    <h1 className="course-title">MERN Full Stack Developer with Network Basic Skills contains:</h1>
                                                    <div className="course-paragraph">

                                                        <p>
                                                            <span>Network Basic Skills: </span>
                                                            Network Fundamentals, Network medium and types of data transport, OSI Layer Model &amp; TCP/IP,
                                                            LAN and WAN Network, Communication Protocols, Networking Equipment, Cloud and Data Center Infrastructure basics Servers.
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>MongoDB: </span>
                                                            MongoDB Installation, CRUD Operation in MongoDB, Query Operators and Data Modeling, Schema Design Pattern, Automatic, Static and External Storage Class Replication and aggregation.
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Express JS and Node JS: </span>
                                                            Node JS modules including Node Package Manager, Creating Web Server, Core Node JS Debugger, Inheriting Events, Express JS, Configuring Routes, Working with Middle Ware, Database Connectivity.
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>React JS: </span>
                                                            Introduction to React. Installation and initial requirements, Building the first application in React, Components and styling in React, Transfer of properties (Props),
                                                            JSX (Javascript XML) Framework, Actions with the &#39;State&#39; option, Working with data in the UI, Working with events (Events),
                                                            Lifecycle methods, Features of functional components – Hooks, Access to DOM elements, Material UI, Building and testing a real application.
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Email Marketing, </span>
                                                            (principles of email marketing, softwares, email automation, CRM leads vs clients…)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Soft Skills: </span>
                                                            Analytical skills, Design skills, Communication skills, Presentations skills, Basics of time management,
                                                            Various types of online outsourcing marketplaces that exist and their utilization in the context of Kosovo,
                                                            How to approach and communicate with different types of clients; Basics of project management
                                                            (use of project management and communication software),
                                                            Making a resume, project portfolio,
                                                            Basic knowledge about finance (Personal finance management, covering taxation) and market,
                                                            Types of tasks that one could undertake on such marketplaces,
                                                            Strategies for finding one’s competitive niche on such marketplaces,
                                                            How to create an online work profile and maintain it while leveraging self-marketing techniques,
                                                            Bidding, proposing and negotiating with clients,
                                                            Ensuring quality and timely delivery of online tasks,
                                                            Receiving payments in the context of Kosovo,
                                                            Building long-term working relationships with online clients.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="course-sidebar">
                                            <div className="course-table">
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Technical skills
                                                    </div>
                                                    <div className="content-description">
                                                        200 hours
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Soft skills
                                                    </div>
                                                    <div className="content-description">
                                                        35 hours
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Total hours
                                                    </div>
                                                    <div className="content-description">
                                                        940 hours for 4 groups
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Who can apply?
                                                    </div>
                                                    <div className="content-description">
                                                        Any person over 16+
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Location
                                                    </div>
                                                    <div className="content-description">
                                                        Str. Qamil Ilazi, No. 16, Ferizaj
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-qr">
                                                <div className="course-button">
                                                    <a href="https://forms.kodeproject.org/formulari-per-aplikim/?mdlcd=t148&prgrm=MERN%20Full%20Stack%20-%20Ferizaj%20-%20Ferizaj" target="_blank" rel="noreferrer" className="btn-default-sm btn-fam">
                                                        <Button variant="outlined" size="medium">
                                                            <FormattedMessage id="Apply" defaultMessage="Apply Now!" />
                                                        </Button>
                                                    </a>
                                                    <p>or scan the qr code</p>
                                                </div>
                                                <img src="/images/qrcodes/mern_qr.png" alt="" />
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            )}
                            {mission && (
                                <div className="content-grid">
                                    <Fade big cascade>
                                        <div className="course-description">
                                            <div className="course-header">
                                                <h1 className="course-title">Digital Marketing</h1>
                                                <p className="course-text">
                                                    This course will introduce and enable students to apply digital marketing and software tools.
                                                    This course also enables students to successfully learn and apply digital marketing concepts,
                                                    techniques and soft skills. This course enables students to prepared and successfully apply
                                                    the concepts and techniques of digital marketing. The purpose of the course is to prepare
                                                    students with modern knowledge in digital marketing. Targets are individuals who are new to
                                                    digital marketing techniques and individuals who have some experience.
                                                </p>
                                            </div>
                                            <div className="course-text">
                                                <div className="course-content">
                                                    <h1 className="course-title">The training will include:</h1>
                                                    <div className="course-paragraph">

                                                        <p>
                                                            <span>Digital Marketing Principles </span>
                                                            understanding basic marketing principles, what is digital marketing, marketplace research and
                                                            analysis, selecting the right market for your business
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Consumer Behavior </span>
                                                            understanding your consumers, consumer research and analysis, targeting and segmentation
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Facebook / Instagram / LinkedIn / Google Ads </span>
                                                            (adds, customer segmentation, add placement,
                                                            objectives, business page, strategies…)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Google Analytics, </span>
                                                            (use, how to increase your ROI, measuring and analysing the data…)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Email Marketing, </span>
                                                            (principles of email marketing, softwares, email automation, CRM leads vs clients…)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Content Writing, </span>
                                                            (how to create: content curation, copy writing techniques for non-writers,
                                                            blogs content, social media content, plagiarism free content)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Search Engine Optimization </span>
                                                            (what is SEO, page optimization, off page optimization, tools, how
                                                            to create effective backlinks, strategies and tools for 100% optimization…)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Media Planning </span>
                                                            (theory on media planning, how to: split media activities, ad budget split, to
                                                            identify the right media…).
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Social Media Marketing </span>
                                                            (theory on social media marketing, principles of SMM, how to create
                                                            content for social media, viral marketing, how to use all social media platforms for one marketing
                                                            strategy community management, content distribution, setup target audience, how to measure
                                                            performance etc.)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Campaign Building </span>
                                                            (how to create a high converting campaign, how to create a viral campaign,
                                                            converting your leads into customers through your funnel…)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Mobile Marketing </span>
                                                            (introduction, importance of mobile marketing, create content, targeting and
                                                            segmentation, data collection and analysis, software’s and platforms for mobile marketing)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Development of an online strategy </span>
                                                            (how to create online strategy, business and campaign
                                                            objectives)
                                                        </p>
                                                    </div>
                                                    <div className="course-paragraph">
                                                        <p>
                                                            <span>Soft Skills: </span>
                                                            Presentations skills,
                                                            Basic knowledge about finance (Personal finance management, covering taxation) and market,
                                                            How to approach and communicate with different types of clients,
                                                            Various types of online outsourcing marketplaces that exist and their utilization in the context of
                                                            Kosovo,
                                                            Types of tasks that one could undertake on such marketplaces,
                                                            Strategies for finding one’s competitive niche on such marketplaces,
                                                            How to create an online work profile and maintain it while leveraging self-marketing techniques,
                                                            Bidding, proposing and negotiating with clients,
                                                            Ensuring quality and timely delivery of online tasks,
                                                            Receiving payments in the context of Kosovo, and
                                                            Building long-term working relationships with online clients,
                                                            Basics of time management,
                                                            Basics of project management (use of project management and communication software),
                                                            Making a resume, project portfolio.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="course-sidebar">
                                            <div className="course-table">
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Technical skills
                                                    </div>
                                                    <div className="content-description">
                                                        164 hours
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Soft skills
                                                    </div>
                                                    <div className="content-description">
                                                        36 hours
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Total hours
                                                    </div>
                                                    <div className="content-description">
                                                        800 hours for 4 groups
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Who can apply?
                                                    </div>
                                                    <div className="content-description">
                                                        Any person over 16+
                                                    </div>
                                                </div>
                                                <div className="course-content">
                                                    <div className="content-title">
                                                        Location
                                                    </div>
                                                    <div className="content-description">
                                                        Str. Qamil Ilazi, No. 16, Ferizaj
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="course-qr">
                                                <div className="course-button">
                                                    <a href="https://forms.kodeproject.org/formulari-per-aplikim/?mdlcd=t155&prgrm=Digital%20Marketing%20-%20Ferizaj%20-%20Ferizaj" target="_blank" rel="noreferrer" className="btn-default-sm btn-fam">
                                                        <Button variant="outlined" size="medium">
                                                            <FormattedMessage id="Apply" defaultMessage="Apply Now!" />
                                                        </Button>
                                                    </a>
                                                    <p>or scan the qr code</p>
                                                </div>
                                                <img src="/images/qrcodes/digitalmarketing_qr.png" alt="" />
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            )}
                        </div>
                    </Fade>

                </div>
                <LogoSlider />
                <div className="course-about">
                    <div className="course-description">
                        <div className="course-header">
                            <h1 className="course-title">About the KODE Project</h1>
                            <p className="course-text">
                                The Government of Kosovo has been supported from the World Bank to implement the Kosovo Digital
                                Economy (KODE) Project. The KODE Project implemented by the Ministry of Economy will finance
                                the critical fundamentals needed for digital transformation and Policy fulfillment. It will
                                provide high speed broadband infrastructure and support access to labor markets, new sources
                                of knowledge, and public services to households and institutions in Project areas.
                                At the national level, the Project will train and connect youth to employment opportunities
                                through YOU Program and improve access to knowledge sources, including to better reaching
                                and collaborations opportunities to High Educational Institutions.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="course-about">
                    <div className="course-description">
                        <div className="course-header">
                            <h1 className="course-title"> About the YOU Program</h1>
                            <p className="course-text">
                                YOU Program will finance provision of training for young people and their connection to
                                working opportunities. The activities will primarily cater to unemployed or underemployed
                                young men and women to increase their ability to compete in relevant segments of work.
                                The Program will skill beneficiaries to perform IT and IT‐enabled services.
                                It is expected that through this training the beneficiaries will increase their
                                employability in the local ICT market.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Trainings);
