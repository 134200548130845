// react
import React, { Component } from 'react';

// third-party
import PropTypes from 'prop-types';
import {
    BrowserRouter,
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import { ScrollContext } from 'react-router-scroll-4';

// application
import languages from '../i18n';
import { localeChange } from '../store/locale';

// pages
import Layout from './Layout';
import HomePageOne from './home/HomePageOne';
import SitePageError from './site/SitePageError';
import News from './NewsBlogs/News';
import AboutUs from './about-us/AboutUs';
import Services from './Services/Services';
import ProjectsPage from './Projects/ProjectsPage';
import Contact from './Contact/Contact';
import Page from './Page/Page';
import NewsDetails from './NewsBlogs/NewsDetails';
import Trainings from './Trainings/Trainings';

class Root extends Component {
    componentDidMount() {
        setTimeout(() => {
            const preloader = document.querySelector('.site-preloader');
            preloader.addEventListener('transitionend', (event) => {
                if (event.propertyName === 'opacity') {
                    preloader.parentNode.removeChild(preloader);
                }
            });
            preloader.classList.add('site-preloader__fade');
        }, 500);
    }

    shouldUpdateScroll = (prevRouterProps, { location }) => (
        prevRouterProps && location.pathname !== prevRouterProps.location.pathname
    );

    render() {
        const { locale } = this.props;
        const { messages, direction } = languages[locale];

        return (
            <IntlProvider locale={locale} messages={messages}>
                <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <HelmetProvider>
                        <Helmet htmlAttributes={{ lang: locale, dir: direction }} />
                        <ScrollContext shouldUpdateScroll={this.shouldUpdateScroll}>
                            <Switch>
                                <Route
                                    exact
                                    path="/Home"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={HomePageOne} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/AboutUs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={AboutUs} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/Services/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Services} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/Projects/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={ProjectsPage} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/Trainings/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Trainings} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Page} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/Services/Page/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Page} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/News&Blogs/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={News} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/NewsDetails/:id/:postimiId"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={NewsDetails} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/Contact/:id"
                                    render={(props) => (
                                        <Layout {...props} headerLayout="default" homeComponent={Contact} ContactBottom="false" />
                                    )}
                                />
                                <Route path="/error">
                                    <SitePageError />
                                </Route>
                                <Redirect to="/Home" />
                            </Switch>
                        </ScrollContext>
                    </HelmetProvider>
                </BrowserRouter>
            </IntlProvider>
        );
    }
}

Root.propTypes = {
    /** current locale */
    locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

const mapDispatchToProps = {
    localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
