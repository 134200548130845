// react
import {
    React,
    Fragment,
    useState,
    useEffect,
} from 'react';
import { connect } from 'react-redux';
import Fade from 'react-reveal/Fade';

import { getData } from '../../services/fetch-service';

function MissionVision(props) {
    const { locale } = props;
    const [missionData, setMission] = useState([]);
    const [visionData, setVisionData] = useState([]);

    useEffect(() => {
        getData(`Page/GetPageSubPages?ParentPageID=2&Gjuha=${locale}`).then((response) => {
            setMission(response.data[0].page)
            setVisionData(response.data[1].page);
        });
    }, []);

    return (
        <Fragment>
            <div className="aboutus-top">
                <div className="photo-menu-aboutus row">
                    <div className="about-us-bg" />
                    <div className="aboutus-mission">
                        <Fade big>
                            <div className="about-feature">
                            <h1 className="footer-parallax-title">{missionData.pageName}</h1>
                                <p dangerouslySetInnerHTML={{ __html: missionData.pageText }} />
                            </div>
                        </Fade>
                        <Fade big>
                            <div className="about-feature">
                                <h1 className="footer-parallax-title">{visionData.pageName}</h1>
                                <p dangerouslySetInnerHTML={{ __html: visionData.pageText }} />
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="overlay" />
            </div>
        </Fragment>
    );
}

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(MissionVision);
