// react
import React from 'react';
// third-party
import { FormattedMessage } from 'react-intl';
import AppLink from '../shared/AppLink';

function SitePageNotFound() {
    return (
        <div className="error-fp">
            <div className="block error-box">
                <div className="container">
                    <div className="not-found">
                        <div className="not-found__404">
                            <h1 className="not-found__title error_title">
                                <FormattedMessage id="Page.Error.Title" defaultMessage="Faqja për momentin nuk është në dispozicion ju lutem provoni më vonë" />
                            </h1>
                        </div>

                        <div className="not-found__content">
                            <AppLink
                                className="btn btn-primary btn-about"
                                to="/Home"
                            >
                                <FormattedMessage id="Page.Error.btn" defaultMessage="" />
                            </AppLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageNotFound;
